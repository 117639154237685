// import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import { Suspense } from 'react';

import AddToCart from 'Component/AddToCart';
import CartIcon from 'Component/CartIcon';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import { ReactElement } from 'Type/Common.type';

import './ProductCard.override.style';

/** @namespace Pwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderPicture(mix = {}): ReactElement {
        const { product: { id, name }, thumbnail, onLoad } = this.props;

        return (
            <div>
                <Image
                  imageRef={ this.imageRef }
                  src={ thumbnail }
                  alt={ name }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                  isPlaceholder={ !id }
                  onImageLoad={ onLoad }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </div>
        );
    }

    renderAddToCart(): ReactElement {
        const {
            showSelectOptionsNotification,
            inStock,
        } = this.props;

        const requiresConfiguration = this.requiresConfiguration();

        if (inStock && requiresConfiguration) {
            return (
                <button
                  block="ProductAddToCart"
                  onClick={ showSelectOptionsNotification }
                >
                    <CartIcon />
                </button>
            );
        }

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <p>
                        { __('Out of stock') }
                    </p>
                </div>
            );
        }

        return this.renderAddToCartButton();
    }

    // #region BUTTONS
    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }

    renderBrand(withMeta = false): ReactElement {
        const {
            product: {
                attributes: { brand: { attribute_value: brand = '' } = {} } = {},
            },
        } = this.props;

        // DA DECOMMENTARE DOPO AVER FATTO DATA ENTRY
        // if (!brand) {
        //     return null;
        // }

        return (
            <>
                { withMeta && <meta itemProp="brand" content={ brand } /> }
                <h4 block={ this.className } elem="Brand" itemProp="brand">
                    { /* DA CANCELLARE DOPO AVER FATTO DATA ENTRY */ }
                    BRAND
                    { /* <TextPlaceholder content={ brand } /> */ }
                </h4>
            </>
        );
    }

    renderProductActions(): ReactElement {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
                { /* { this.renderProductCompareButton() } */ }
            </div>
        );
    }

    renderSku(): ReactElement {
        const { getActiveProduct } = this.props;
        const { sku } = getActiveProduct();

        return (
            <span block="ProductCard" elem="Sku" itemProp="sku">
                <b>{ __('Codice') }</b>
                { ' ' }
                <TextPlaceholder content={ sku } />
            </span>
        );
    }

    renderCardContent(): ReactElement {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { /* { isMobile && (
                                <div block="ProductCard" elem="MobileActions">
                                    { this.renderProductCardWishlistButton() }
                                    { this.renderProductCompareButton() }
                                </div>
                            ) } */ }
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { /* { this.renderReviews() } */ }
                        { this.renderBrand() }
                        { this.renderName(false) }
                        { this.renderSku() }
                        <div block="ProductCard" elem="Bottom">
                            { this.renderPrice() }
                            <div block="ProductCard" elem="Footer">
                                { this.renderProductActions() }
                                { this.renderAddToCart() }
                            </div>
                        </div>
                        { /* { this.renderConfigurableOptions() } */ }
                    </div>
                </div>
            ))
        );
    }
}

export default ProductCardComponent;
